import { auth } from "@/services/firebase/fire";
import {
  initializeMixPanel,
  initializeMixPanelUser,
  startTrackTimedEvent,
  trackEvent,
  trackTimedEvent,
} from "../../util/mixpanel";
import { initializeLogRocketUser } from "@/util/logrocket";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  setClientCode,
  setCurrentPage,
  setEmailAndMobile,
  setInitialLoading,
  setKeyboardOpen,
  setMarketfeedDetails,
  setNextPage,
  setOtpVerified,
  useStoreData,
} from "../../redux/onboardingSlice";
import { customTokenLoginThunk, fetchUserAccountStatusThunk } from "@/redux/onboardingThunks";
import { mixpanelEvents } from "@/constant/mixpanelEvents";
import { pageMapping } from "@/constant/pageMapping";
import { PAGES } from "@/constant/misc";
import { customTokenLogin } from "@/services/firebase/firebase-client";

const PageChangeListener = (props) => {
  const esignStatus = props.queries.status;
  const digiLockerStatus = props.queries["digilocker-status"];
  const router = useRouter();
  const dispatch = useDispatch();
  const {
    nextPage,
    currentPage,
    clientCode,
    email,
    mobile,
    stage,
    isLoggedIn,
    marketfeedDetails,
    otpVerified,
  } = useStoreData();

  /*
   - Sets clientCode and userCredentials from sessionStorage if it is present
   - Sets currentPage to current pages pathname => for initial page load
   - Listens to routeChangeComplete => sets nextPage = null, sets currentPage => for subsequent page reroutes
   - Listens to keyboard up event in webview to change padding of page 
  */

  useEffect(() => {
    initializeMixPanel();
    const localClientCode = window.localStorage.getItem("clientCode");
    const userCredentials = window.localStorage.getItem("userCredentials");
    if (
      (!localClientCode || !userCredentials) &&
      ![
        PAGES.LOADING,
        PAGES.APP_LOADING,
        PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_DDPI,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_FNO,
        PAGES.LINK_ACCOUNT_AUTHORISE_TRADES,
        PAGES.LINK_ACCOUNT_HOW_TO_RESOLVE,
      ].includes(router.pathname)
    ) {
      router.replace(PAGES.VERIFY);
    }
    if (!clientCode) {
      dispatch(setClientCode(localClientCode));
    }
    if (!mobile || !email) {
      dispatch(setEmailAndMobile(JSON.parse(userCredentials)));
    }
    dispatch(setCurrentPage(router.pathname));
    if (
      stage ||
      pageMapping?.[router.pathname]?.pageName ===
        pageMapping?.[PAGES.VERIFY]?.pageName
    ) {
      trackEvent({
        eventName: mixpanelEvents.MIXPANEL_VIEW_USER_ONBOARDING_PAGE,
        page: pageMapping?.[router.pathname]?.pageName,
      });
      startTrackTimedEvent();
    }
    router.events.on("routeChangeStart", (url) => {
      trackTimedEvent({
        eventName: mixpanelEvents.MIXPANEL_VIEW_USER_ONBOARDING_PAGE,
      });
    });
    router.events.on("routeChangeComplete", (url) => {
      dispatch(setInitialLoading(false));
      trackEvent({
        eventName: mixpanelEvents.MIXPANEL_VIEW_USER_ONBOARDING_PAGE,
        page: pageMapping[url.replace("/onboarding", "")]?.pageName,
      });
      startTrackTimedEvent();
      dispatch(setNextPage(null));
      dispatch(setCurrentPage(url.replace("/onboarding", ""))); //onboarding is removed from route since routeChangeEvent returns along with basePath
    });
    window.visualViewport.addEventListener("resize", (e) => {
      const MIN_KEYBOARD_HEIGHT = 300;

      const isMobile = window.innerWidth < 768;
      const isKeyboardOpen =
        isMobile &&
        window.screen.height - MIN_KEYBOARD_HEIGHT >
          window.visualViewport.height;
      if (isKeyboardOpen) {
        dispatch(setKeyboardOpen(true));
      } else {
        dispatch(setKeyboardOpen(false));
      }
    });

      const userLoggedIn = window.localStorage.getItem('userLoggedIn');
      if(!JSON.parse(userLoggedIn) && !props.queries.token && ![PAGES.APP_LOADING].includes(router.pathname)){
        const url = new URL(`${process.env.NEXT_PUBLIC_MARKETFEED_WEB_LOGIN}`);
        const currentUrl = new URL(window.location.href)
        const mobileNumberFromUrl = currentUrl.searchParams.get('m');
        window.location.href = mobileNumberFromUrl ? `${url}?redirectTo=${window.location.href.split('?')[0]}&mobileNumber=${mobileNumberFromUrl}` : `${url}?redirectTo=${window.location.href.split('?')[0]}`
      }else if(props.queries.token){
        dispatch(customTokenLoginThunk(props.queries.token))
      }
  }, []);

  useEffect(() => {
    if (
      stage &&
      pageMapping?.[router.pathname]?.pageName !==
        pageMapping?.[PAGES.VERIFY]?.pageName
    ) {
      trackEvent({
        eventName: mixpanelEvents.MIXPANEL_VIEW_USER_ONBOARDING_PAGE,
        page: pageMapping?.[router.pathname]?.pageName,
      });
      startTrackTimedEvent();
    }
  }, [stage]);

  /*
    - if digilocker or esign queryparams set otp verified true
    - if otp is not verified redirect to verify
    - if otp is verified check if stage is present 
    - if stage is present redirect to corresponding page
  */

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_TESTING_ENVIRONMENT &&
      !JSON.parse(process.env.NEXT_PUBLIC_TESTING_ENVIRONMENT)
    ) {
      if (digiLockerStatus || esignStatus) {
        dispatch(setOtpVerified(true));
      }
    }
  }, [otpVerified]);

  /*
    - if email and mobile changes in store, the same is updated in sessionStorage
  */

  useEffect(() => {
    const localUserCredentials = window.localStorage.getItem("userCredentials");
    let localUserCredentialsJson = JSON.parse(localUserCredentials);
    if (localUserCredentialsJson) {
      if (
        localUserCredentialsJson.email !== email &&
        localUserCredentialsJson.mobile !== mobile
      ) {
        if (email && mobile) {
          window.localStorage.setItem(
            "userCredentials",
            JSON.stringify({ email: email, mobile: mobile })
          );
        }
      }
    } else {
      if (email && mobile) {
        window.localStorage.setItem(
          "userCredentials",
          JSON.stringify({ email: email, mobile: mobile })
        );
      }
    }
  }, [email, mobile]);

  /*
   - if clientCode changes in store, the same is updated in sessionStorage
  */

  useEffect(() => {
    const localClientCode = window.localStorage.getItem("clientCode");
    if (localClientCode) {
      if (localClientCode !== clientCode) {
        if (clientCode) {
          window.localStorage.setItem("clientCode", clientCode);
        }
      }
    } else {
      if (clientCode) {
        window.localStorage.setItem("clientCode", clientCode);
      }
    }
  }, [clientCode]);

  /*
   - Redirects to nextPage if set in store
  */

  useEffect(() => {
    if (nextPage) {
      router.push(nextPage);
    }
  }, [nextPage]);

  /*
   - if marketfeedDetails is set in store, user stage is fetched
  */

  useEffect(() => {
    if (
      marketfeedDetails &&
      marketfeedDetails.mobileNumber &&
      marketfeedDetails.uid
    ) {
      initializeMixPanelUser(marketfeedDetails.uid);
      initializeLogRocketUser(
        marketfeedDetails.uid,
        marketfeedDetails.mobileNumber
      );
      dispatch(fetchUserAccountStatusThunk({}));
    }
  }, [marketfeedDetails]);

  /*
   - if user is logged in with custom token, marketfeedDetails is set in store
  */

  useEffect(() => {
    if (auth.currentUser) {
      const { phoneNumber, uid } = auth.currentUser;
      if (phoneNumber && uid) {
        dispatch(setMarketfeedDetails({ mobileNumber: phoneNumber, uid: uid }));
      }
    }
  }, [auth.currentUser]);

  return <></>;
};

export default PageChangeListener;
